 import TakealotLogo from "../../assets/TakealotLogo.svg";
import AmazonLogo from "../../assets/AmazonLogo.svg";

function CallToAction() {
    return (
        // <div className="cta-container">
        //     <h2 className="title-alt">Order Now From These Stores</h2>
        //     <div className="logo-container">
        //     <a href="https://www.takealot.com/" target="_blank" rel="noreferrer" ><img src={TakealotLogo} alt="Takealot Logo" /></a>
        //     <a href="https://www.amazon.com/" target="_blank" rel="noreferrer"><img src={AmazonLogo} alt="Amazon Logo" /></a>
        //     </div>
        // </div>
        <div className="coming-soon-container">
            <h2 className="title-coming-soon">Takealot & Amazon Listings Coming Soon!</h2>
        </div>
    );
}

export default CallToAction;
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import InvestigatorsGuideToOsint from './pages/OsintBook';
import ThinkBeforeYouClick from './pages/ThinkBeforeBook';
import NotFound from './pages/NotFound';
import Navbar from './components/Navbar';
import ContactUs from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <>
    <Navbar/>
      <Routes>
        <Route path='/' exact Component={InvestigatorsGuideToOsint}/>
        <Route path='/investigators-guide-to-osint'  Component={InvestigatorsGuideToOsint}/>
        <Route path='/think-before-you-click'  Component={ThinkBeforeYouClick}/>
        <Route path='*' element={<NotFound />}/>
      </Routes>
      <ContactUs/>
      <Footer/>
    </>
  );
}

export default App;

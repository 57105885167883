import React from 'react';
import { Navigate } from 'react-router-dom';

function NotFound() {
  const redirectPath = '/think-before-you-click';

  return <Navigate to={redirectPath} />;
};

export default NotFound;

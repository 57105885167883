import React, { useState } from "react";
import OsintBookFront from "../../assets/OsintBook.svg";
import OsintBookBack from "../../assets/OsintBookBack.svg";
import OsintBookFrontLow from "../../assets/OsintBookFront.png";
import OsintBookBackLow from "../../assets/OsintBookBack.png";

function Hero() {

    const [frontCoverLoaded, setFrontCoverLoaded] = useState(false);
    const [backCoverLoaded, setBackCoverLoaded] = useState(false);

    const loadHighQualityImages = () => {
        setFrontCoverLoaded(true);
        setBackCoverLoaded(true);
      };

    return (
        <div>
            <div className="hero-container">
                <div />
                <div>
                    <div className="hero-title">
                        <h1>In the digital age, <br/><span className="accent">Open Source Intelligence</span><br/> is king.</h1>
                    </div>
                </div>
            </div>
            <div className="hero-details">
                <div>
                    <div className="book-cover-container">
                        <div className="flip-card">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={frontCoverLoaded ? OsintBookFront : OsintBookFrontLow} alt="Osint Book Front Cover" onLoad={loadHighQualityImages} />
                                </div>
                                <div className="flip-card-back">
                                    <img src={backCoverLoaded ? OsintBookBack : OsintBookBackLow} alt="Osint Book Front Cover" onLoad={loadHighQualityImages} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="order-controls">
                        {/* <a href="https://www.takealot.com/" target="_blank" rel="noreferrer"><button className="order-takealot-btn">Takealot Coming Soon</button></a>
                        <a href="https://www.amazon.com/" target="_blank" rel="noreferrer"><button className="order-amazon-btn">Amazon Coming Soon</button></a> */}
                        <button className="order-takealot-btn">Takealot Orders Coming Soon</button>
                        <button className="order-amazon-btn">Amazon Orders Coming Soon</button>
                    </div>
                </div>
                <div className="book-about">
                    <p>The Investigators Guide to OsInt is written from experience gathered over two decades and solely for the South African market.</p>
                    <p>It is a self-study style manual that will guide investigators through the basic steps and skillsets and how to apply collected data during an investigation.</p>
                    <p>The purpose of this manual is to bridge the gap between curiosity and commitment to taking a full training course.</p>
                    <p>No investigator should be without these skills. Navigating the internet with a proper investigative mindset is vital to collecting evidence in both online and offline investigations. </p>
                    <p>Invesigators Guide to OsInt offers the first step in understanding the fundamentals.</p>
                </div>
            </div>
        </div>
    );
}

export default Hero;
import React, { useState } from "react"
import ThinkBeforeBookFront from "../../assets/Think-Before-You-Click(Front).png"
import ThinkBeforeBookBack from "../../assets/Think-Before-You-Click(Back).png"
import ThinkBeforeBookFrontLow from "../../assets/Think-Before-You-Click(Front)low.png"
import ThinkBeforeBookBackLow from "../../assets/Think-Before-You-Click(Back)low.png"

function Hero() {

    const [frontCoverLoaded, setFrontCoverLoaded] = useState(false);
    const [backCoverLoaded, setBackCoverLoaded] = useState(false);

    const loadHighQualityImages = () => {
        setFrontCoverLoaded(true);
        setBackCoverLoaded(true);
      };

    return (
        <div>
            <div className="hero-container">
                <div />
                    <div>
                    <div className="hero-title">
                        <h1>A South African Guide to Cybercrime <br/><span className="accent">Think Before You Click</span></h1>
                    </div>
                </div>
            </div>
            <div className="hero-details">
                <div>
                    <div className="book-cover-container">
                        <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={frontCoverLoaded ? ThinkBeforeBookFront : ThinkBeforeBookFrontLow} alt="Think Before You Click Book Front Cover" onLoad={loadHighQualityImages} />
                            </div>
                            <div className="flip-card-back">
                                <img src={backCoverLoaded ? ThinkBeforeBookBack : ThinkBeforeBookBackLow} alt="Think Before You Click Book Front Cover" onLoad={loadHighQualityImages} />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="order-controls">
                        {/* <a href="https://www.takealot.com/" target="_blank" rel="noreferrer"><button className="order-takealot-btn">Takealot Coming Soon</button></a>
                        <a href="https://www.amazon.com/" target="_blank" rel="noreferrer"><button className="order-amazon-btn">Amazon Coming Soon</button></a> */}
                        <button className="order-takealot-btn">Takealot Orders Coming Soon</button>
                        <button className="order-amazon-btn">Amazon Orders Coming Soon</button>
                    </div>
                </div>
                <div className="book-about">
                    <p>A vital guide delving into South Africa's cybercrime landscape. This comprehensive resource equips readers to navigate the digital age responsibly.</p>
                    <p>Readers gain practical strategies to fortify their digital defenses, such as creating robust passwords and recognizing phishing attempts, ensuring personal safety.</p>
                    <p>Drawing on expert advice, it provides step-by-step guidance on securing personal information and implementing effective security measures. </p>
                    <p>This concise guide invites readers to enhance their digital awareness and fortify the nation's defenses against the growing menace of cybercrime.</p>
                </div>
            </div>
        </div>
    );
}

export default Hero;